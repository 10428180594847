<template>
  <BlockUserDetails :user="user" :loading="loading">
    <fw-panel
      v-if="role == 'leaguesManagers'"
      :title="$t('leagues')"
      :loading="savingData"
      after-loading-checked
      boxed="sm"
      custom-class="bg-gray-100"
    >
      <fw-label>{{ $t(`role.league-manager`) }}</fw-label>
      <div class="flex gap-1 items-center flex-wrap mb-2">
        <b-checkbox
          v-for="(league, key) in leagues"
          :key="key"
          v-model="selectedLeagues"
          :disabled="!validations.can_add_league_managers"
          :native-value="key"
          class="pt-2"
          @input="save"
          >{{ league.title[language] }}</b-checkbox
        >
      </div>

      <fw-tip v-if="$v.selectedLeagues.$error" error>
        {{ $t('chooseAtLeastOneLeague') }}
      </fw-tip>
    </fw-panel>
    <fw-panel
      v-else-if="role == 'teamGroupsManagers'"
      :title="$t('teamGroups')"
      :loading="savingData"
      after-loading-checked
      boxed="sm"
      custom-class="bg-gray-100"
    >
      <fw-label>{{ $t('teamGroupsToAdd') }}</fw-label>

      <multiselect
        v-model="selectedTeamGroups"
        :disabled="!validations.can_add_team_group_managers"
        :options="Object.values(teamGroups)"
        :show-labels="false"
        :clear-on-select="false"
        :allow-empty="false"
        :placeholder="$t('selectTeamGroupsToAdd')"
        :multiple="true"
        track-by="key"
        label="name"
        :searchable="true"
        :custom-label="teamGroupLabel"
        @input="save"
      ></multiselect>

      <fw-tip v-if="$v.selectedTeamGroups.$error" error>
        {{ $t('chooseAtLeastOneTeamGroup') }}
      </fw-tip>
    </fw-panel>
  </BlockUserDetails>
</template>

<script>
import BlockUserDetails from '@/fw-modules/fw-core-vue/id/components/blocks/BlockUserDetails'
import { requiredIf } from 'vuelidate/lib/validators'
import Multiselect from 'vue-multiselect'

export default {
  name: 'UserDetails',
  components: { BlockUserDetails, Multiselect },
  props: {
    user: {
      type: Object,
      default: () => {},
    },
    leagues: {
      type: Object,
      default: () => {},
    },
    teamGroups: {
      type: Object,
      default: () => {},
    },
    userLeagues: {
      type: Array,
      default: () => [],
    },
    userTeamGroups: {
      type: Array,
      default: () => [],
    },
    role: {
      type: String,
      required: true,
    },
    validations: {
      type: Object,
      default: () => {},
    },
    savingData: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
      selectedLeagues: [],
      selectedTeamGroups: [],
    }
  },

  validations: {
    selectedLeagues: {
      required: requiredIf(nestedModal => {
        return nestedModal.role === 'leaguesManagers'
      }),
    },
    selectedTeamGroups: {
      required: requiredIf(nestedModal => {
        return nestedModal.role === 'teamGroupsManagers'
      }),
    },
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    loggedUser() {
      return this.$store.getters.getUser
    },

    language() {
      return this.$store.state.language || 'pt'
    },
  },

  mounted() {
    console.log('this.role :>> ', this.role)
    if (this.role == 'leaguesManagers') {
      this.selectedLeagues = this.userLeagues
    } else if (this.role == 'teamGroupsManagers') {
      for (const teamGroupKey of this.userTeamGroups) {
        this.selectedTeamGroups.push(this.teamGroups[teamGroupKey])
      }
      console.log('this.selectedTeamGroups :>> ', this.selectedTeamGroups)
    }
  },

  methods: {
    teamGroupLabel(teamGroup) {
      return `${teamGroup.organic_unit.name} - ${teamGroup.name}`
    },

    save() {
      console.log('save')
      this.$v.$touch()
      if (this.$v.$invalid) return

      if (this.role == 'teamGroupsManagers') {
        this.$emit('save', {
          users: [this.user],
          selectedTeamGroups: this.selectedTeamGroups.map(el => el.key),
        })
      } else {
        this.$emit('save', {
          users: [this.user],
          selectedLeagues: this.selectedLeagues,
        })
      }
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "leagues": "Ligas",
    "noLeagues": "Sem ligas atribuídas.",
    "teamGroups": "Núcleos",
    "teamGroupsToAdd": "Núcleos",
    "selectTeamGroupsToAdd": "Selecione pelo menos um núcleo",
    "role": {
      "league-manager": "Gestor da(s) fila(s)"
    },
    "chooseAtLeastOneLeague": "Escolha pelo menos uma fila",
    "chooseAtLeastOneTeamGroup": "Escolha pelo menos um núcleo"
  },
  "en": {
    "leagues": "Leagues",
    "noLeagues": "No leagues assigned.",
    "teamGroups": "Núcleos",
    "teamGroupsToAdd": "Núcleos",
    "selectTeamGroupsToAdd": "Selecione pelo menos um núcleo",
    "role": {
      "league-manager": "League manager"
    },
    "chooseAtLeastOneLeague": "Choose at least one league",
    "chooseAtLeastOneTeamGroup": "Escolha pelo menos um núcleo"
  }
}
</i18n>
