<template>
  <div>
    <fw-panel v-if="tmpMessage" :title="tmpMessage.key ? $t('message') : $t('newMessage')">
      <template v-if="canEdit">
        <div v-if="step == 'message'" class="mt-2 mb-5">
          <fw-heading size="md" class="mb-2">Mensagem</fw-heading>

          <fw-label>{{ $t('description.label') }}</fw-label>
          <HtmlEditor
            v-model="tmpMessage.description"
            class="mb-1.5 w-full content"
            editor-class="min-h-40"
            :placeholder-text="$t('description.placeholder')"
          />

          <fw-tip v-if="$v.tmpMessage.description.$error" error>
            <span v-if="!$v.tmpMessage.description.required">{{ $t('errors.requiredMessage') }}</span>
            <span v-else>{{ $t('errors.invalidMessage') }}</span>
          </fw-tip>
        </div>

        <div v-if="step == 'recipients'">
          <fw-heading size="md" class="mb-2">{{ $t('recipients') }}</fw-heading>

          <div class="flex flex-col gap-4">
            <div v-if="isAcademicLeague">
              <fw-label>Fase do torneio</fw-label>
              <div class="flex flex-wrap gap-2">
                <button
                  class="filter-tag"
                  :class="{ active: !tmpMessage.tournament_stage?.length }"
                  @click="selectFilter('tournament_stage', 'all')"
                >
                  Todos
                </button>

                <button
                  v-for="(label, key) in tournamentStages"
                  :key="key"
                  class="filter-tag"
                  :class="{ active: tmpMessage.tournament_stage?.includes(key) }"
                  @click="selectFilter('tournament_stage', key)"
                >
                  {{ label[language] }}
                </button>
              </div>
            </div>

            <div>
              <fw-label>Género</fw-label>
              <div class="flex flex-wrap gap-2">
                <button
                  class="filter-tag"
                  :class="{ active: !tmpMessage.gender?.length }"
                  @click="selectFilter('gender', 'all')"
                >
                  Todos
                </button>

                <button
                  v-for="(label, key) in gendersList"
                  :key="key"
                  class="filter-tag"
                  :class="{ active: tmpMessage.gender?.includes(key) }"
                  @click="selectFilter('gender', key)"
                >
                  {{ label[language] }}
                </button>
              </div>
            </div>

            <div>
              <fw-label>Estado da equipa</fw-label>
              <div class="flex flex-wrap gap-2">
                <button
                  class="filter-tag"
                  :class="{ active: !tmpMessage.team_state?.length }"
                  @click="selectFilter('team_state', 'all')"
                >
                  Todos
                </button>

                <button
                  v-for="({ label }, key) in teamsStatus"
                  :key="key"
                  class="filter-tag"
                  :class="{ active: tmpMessage.team_state?.includes(key) }"
                  @click="selectFilter('team_state', key)"
                >
                  {{ label[language] }}
                </button>
              </div>
            </div>

            <div>
              <fw-label>Modalidade</fw-label>
              <multiselect
                v-model="tmpMessage.sport_key"
                :options="sportsOptions"
                :show-labels="false"
                :clear-on-select="false"
                :allow-empty="true"
                :multiple="true"
                track-by="key"
                label="key"
                placeholder="Selecione uma modalidade"
                :searchable="true"
                :options-limit="limit"
                :custom-label="sportLabel"
                :loading="loadingSports"
                @search-change="getSports"
              ></multiselect>
            </div>

            <div>
              <fw-label>Núcleo</fw-label>
              <multiselect
                v-model="tmpMessage.team_group"
                :options="teamGroupsOptions"
                :show-labels="false"
                :clear-on-select="false"
                :allow-empty="true"
                :multiple="true"
                track-by="key"
                label="key"
                placeholder="Selecione um núcleo"
                :searchable="true"
                :options-limit="limit"
                :custom-label="teamGroupLabel"
                :loading="loadingTeamGroups"
                @search-change="getTeamGroups"
              >
              </multiselect>
            </div>
          </div>
        </div>

        <template v-if="step == 'confirm'">
          <fw-panel-info v-if="checkingTeams" clean class="mx-auto h-16 flex items-center justify-center  m-5">
            <fw-icon-loading class="animate-spin w-10 h-10 m-auto" />
            Verificando o número de equipas...
          </fw-panel-info>
          <div v-else>
            <div class="rounded-full mx-auto w-16 h-16 bg-primary flex items-center justify-center m-5">
              <fw-icon-message-sent class="fill-current h-8 w-8 text-white"></fw-icon-message-sent>
            </div>
            <p class="text-gray-500">
              Vão ser enviadas mensagems para <strong> {{ teamsCount ?? 0 }} equipa(s)</strong>.
            </p>
          </div>
        </template>
      </template>

      <template v-else>
        <div class="mt-2">
          <fw-label>{{ $t('description.label') }}</fw-label>
          <fw-panel-info clean>
            <span v-html="tmpMessage.description"></span>
          </fw-panel-info>
        </div>

        <div class="mt-4">
          <fw-label>{{ $t('recipients') }}</fw-label>
          <fw-panel-info clean>
            Enviada para <strong> {{ tmpMessage.team_keys?.length ?? 0 }} equipa(s)</strong>
          </fw-panel-info>
        </div>

        <div class="mt-4">
          <fw-label>Criada em</fw-label>
          <fw-panel-info clean> {{ tmpMessage.created_at | formatDateTime }} </fw-panel-info>
        </div>

        <div v-if="tmpMessage.user && tmpMessage.user.key" class="flex gap-2 items-center mt-4">
          <fw-avatar size="xs" :user="tmpMessage.user" class="flex-shrink-0" />

          <span>{{ tmpMessage.user.display_name || tmpMessage.user.full_name }}</span>
        </div>
      </template>
    </fw-panel>

    <div v-if="canEdit" class="flex items-center justify-end gap-5 mt-5">
      <fw-button type="link-muted" :disabled="savingMessage" class="mr-auto" @click.native="close">
        {{ $t('cancel') }}
      </fw-button>

      <fw-button v-if="step != 'message'" type="link-muted" :disabled="savingMessage" @click.native="previousStep()">
        {{ $t('back') }}
      </fw-button>

      <fw-button v-if="step == 'message'" type="primary" class="px-5" @click.native="nextStep()">
        {{ $t('selectRecipients') }}
      </fw-button>

      <fw-button v-else-if="step == 'recipients'" type="primary" class="px-5" @click.native="nextStep()">
        {{ $t('confirm') }}
      </fw-button>
      <fw-button
        v-else-if="step == 'confirm'"
        type="primary"
        class="px-5"
        :disabled="savingMessage"
        :loading="savingMessage"
        @click.native="sendMessage"
      >
        <template #icon>
          <fw-icon-send-plane class="w-5 h-5" />
        </template>
        {{ $t('send') }}
      </fw-button>
    </div>
    <div v-else class="flex items-center justify-end gap-5 mt-5">
      <fw-button type="link-muted" @click.native="close">
        {{ $t('close') }}
      </fw-button>
    </div>
  </div>
</template>

<script>
import HtmlEditor from '@/fw-modules/fw-core-vue/ui/components/form/HtmlEditor'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'

import Multiselect from 'vue-multiselect'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import { GENDERS, TOURNAMENT_TYPES, TOURNAMENT_STAGES, TEAM_STATES } from '@/utils/index.js'

export default {
  components: {
    HtmlEditor,
    Multiselect,
  },

  validations: {
    tmpMessage: {
      description: { required, min: minLength(2), max: maxLength(65000) },
    },
  },

  props: {
    message: {
      type: Object,
      default: () => {
        return {
          description: '',
        }
      },
    },
    sports: {
      type: Array,
      default: () => [],
    },
    teamGroups: {
      type: Array,
      default: () => [],
    },
    savingMessage: {
      type: Boolean,
      default: false,
    },
    isAcademicLeague: {
      type: Boolean,
      default: false,
    },
    editionKey: {
      type: String,
    },
    leagueKey: {
      type: String,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      limit: 50,
      updateFilesToUpload: [],
      recipients: [],
      tmpMessage: {},
      teamsStatus: TEAM_STATES,
      gendersList: GENDERS,
      tournamentStages: TOURNAMENT_STAGES,
      typesList: TOURNAMENT_TYPES,
      loadingSports: false,
      loadingTeamGroups: false,
      filtersOpen: false,
      sportsOptions: [],
      teamGroupsOptions: [],
      step: 'message',
      teamsCount: null,
      checkingTeams: false,
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    language() {
      return this.$store.state.language || 'pt'
    },
  },

  mounted() {
    this.tmpMessage = {
      ...JSON.parse(JSON.stringify(this.message)),
      team_state: [],
      gender: [],
      tournament_stage: [],
    }
    this.sportsOptions = this.sports
    this.teamGroupsOptions = this.teamGroups
  },

  methods: {
    sportLabel(sport) {
      return `${sport.modality?.title?.[this.language]} ${sport.category?.[this.language] ?? ''}`
    },

    teamGroupLabel(teamGroup) {
      return `${teamGroup.organic_unit?.name} - ${teamGroup.name}`
    },

    previousStep() {
      if (!this.canEdit) return
      if (this.step == 'recipients') {
        this.step = 'message'
        return
      }

      if (this.step == 'confirm') {
        this.step = 'recipients'
        return
      }
    },

    nextStep() {
      if (!this.canEdit) return
      this.$v.$touch()
      if (this.$v.$invalid) return

      if (this.step == 'message') {
        this.step = 'recipients'
        return
      }

      if (this.step == 'recipients') {
        this.getTeamsCount()
        this.step = 'confirm'
        return
      }

      if (this.step == 'message') {
        this.step = 'recipients'
        return
      }
    },

    getTeamsCount() {
      utils.tryAndCatch(
        this,
        async () => {
          this.checkingTeams = true
          const response = await this.api.sendGlobalMessages(this.editionKey, this.leagueKey, {
            ...this.tmpMessage,
            team_group: this.tmpMessage.team_group?.map(el => el.key) ?? [],
            sport_key: this.tmpMessage.sport_key?.map(el => el.key) ?? [],
            confirm: false,
          })
          console.log('checkMessage :>> ', response)
          this.teamsCount = response.count
        },
        async () => {
          await utils.sleep(500)
          this.checkingTeams = false
        }
      )
    },

    close() {
      this.$v.$reset()
      this.$emit('close')
    },

    openFilters() {
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.filtersOpen = true
    },

    sendMessage() {
      if (!this.canEdit) return

      this.$v.$touch()
      if (this.$v.$invalid) return

      if (this.teamsCount > 0) {
        this.$buefy.dialog.confirm({
          confirmText: 'Enviar mensagem',
          cancelText: 'Cancelar',
          title: `Enviar mensagem global`,
          message: `Esta mensagem vai ser enviada para ${this.teamsCount} equipas. Tem a certeza que deseja enviar?`,
          onConfirm: () => {
            this.$emit('send', {
              ...this.tmpMessage,
              team_group: this.tmpMessage.team_group?.map(el => el.key) ?? [],
              sport_key: this.tmpMessage.sport_key?.map(el => el.key) ?? [],
            })
            this.close()
          },
        })
      } else {
        this.$buefy.dialog.alert({
          title: 'Enviar mensagem global',
          message: 'Não há equipas para quem enviar a mensagem.',
          type: 'is-danger',
          confirmText: 'Ok',
        })
      }
    },

    selectFilter(key, value) {
      console.log({ key, value })

      const hasItems = this.tmpMessage[key].length > 0
      const hasNewVal = this.tmpMessage[key].includes(value)

      if (value == 'all' && hasItems) {
        this.tmpMessage[key] = []
      } else if (hasNewVal) {
        const newVal = this.tmpMessage[key].filter(el => value != el)

        this.tmpMessage[key] = newVal
      } else {
        this.tmpMessage[key] = this.tmpMessage[key].concat([value])
      }
    },

    // For multiselect
    async getSports(query) {
      this.loadingSports = true
      utils.tryAndCatch(
        this,
        async () => {
          const response = await this.api.getSports({ query, limit: this.limit })
          console.log('getSports :>> ', response)
          this.sportsOptions = response.sports
        },
        () => {
          this.loadingSports = false
        }
      )
    },

    // For multiselect
    async getTeamGroups(query) {
      this.loadingTeamGroups = true
      utils.tryAndCatch(
        this,
        async () => {
          const response = await this.api.getTeamGroups({
            query,
            with_org_units: true,
            limit: this.limit,
          })
          console.log('getTeamGroups :>> ', response)
          this.teamGroupsOptions = response.groups.sort((a, b) =>
            `${a.organic_unit.name} - ${a.name}`.localeCompare(`${b.organic_unit.name} - ${b.name}`)
          )
        },
        () => {
          this.loadingTeamGroups = false
        }
      )
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "close": "Fechar",
    "save": "Guardar",
    "cancel": "Cancelar",
    "delete": "Eliminar",
    "send": "Enviar",
    "back": "Voltar",
    "recipients": "Destinatários",
    "selectRecipients": "Definir destinatários",
    "confirm": "Confirmar",
    "description": {
      "label": "Mensagem",
      "placeholder": "Escreva aqui a sua mensagem"
    },
    "message": "Mensagem",
    "editMessage": "Editar mensagem",
    "files": "Ficheiros",
    "newMessage": "Nova mensagem",
    "publicMessage": "Mensagem não interna",
    "privateMessages": "Mensagem interna",
    "messageIsPublicConfirm": "A mensagem que está prestes a enviar não é interna. Tem a certeza que deseja continuar?",
    "sendMessageConfirm": "Tem a certeza que deseja enviar esta mensagem?",
    "selectThisOption": "Selecione esta opção para que esta mensagem seja apenas vísiveis a gestores e operadores.",
    "sendMessage": "Enviar mensagem",
    "noMessages": "Sem mensagems",
    "addMessage": "Adicionar nova mensagem",
    "deleteFileConfirm": "Tem a certeza que deseja eliminar o ficheiro?",
    "uploadFiles": "Carregar ficheiros",
    "deleteMessageConfirm": "Tem a certeza que deseja eliminar a mensagem?",
    "errors": {
      "requiredMessage": "Insira uma mensagem",
      "invalidMessage": "mensagem inválida"
    }
  },
  "en": {
    "save": "Save",
    "close": "Close",
    "cancel": "Cancel",
    "delete": "Delete",
    "recipients": "Recipients",
    "back": "Back",
    "selectRecipients": "Select recipients",
    "confirm": "Confirm",
    "send": "Send",
    "description": {
      "label": "Message",
      "placeholder": "Write your message"
    },
    "files": "Ficheiros",
    "message": "Message",
    "editMessage": "Edit message",
    "newMessage": "New message",
    "publicMessage": "Non-internal message",
    "privateMessages": "Private message",
    "messageIsPublicConfirm": "You are about to send a non-internal message. Are you sure?",
    "sendMessageConfirm": "Are you sure you want to send this message?",
    "selectThisOption": "Select this option for messages to be visible only by those responsible.",
    "noMessages": "No messages",
    "addMessage": "Add new message",
    "sendMessage": "Send message",
    "deleteFileConfirm": "Are you sure you want to delete the file?",
    "uploadFiles": "Upload files",
    "errors": {
      "requiredMessage": "Enter a message",
      "invalidMessage": "Invalid message"
    }
  }
}
</i18n>

<style lang="postcss">
.filter-tag {
  @apply px-2.5 select-none py-1 flex items-center cursor-pointer bg-gray-100 text-gray-600 rounded-lg text-sm;
}
.filter-tag.active {
  @apply bg-primary text-white font-medium;
}
</style>
