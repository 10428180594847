<template>
  <button
    class="border-b border-gray-100 relative rounded-lg px-3 py-2.5 hover:bg-gray-200 hover:bg-opacity-50 group flex items-center w-full gap-2 text-left"
    @click="$emit('open')"
  >
    <div class="flex-1 flex items-center">
      <fw-icon-arrow-right class="min-w-[1.25rem] w-5 h-5 text-gray-500 mr-1" />
      <span class="text-gray-500 mr-2">{{ message.key }}</span>
      <div class="line-clamp-1" v-html="message.description"></div>
    </div>

    <div class="w-28">
      <fw-tag :type="message.status == 'sent' ? 'light-primary' : 'light'">
        {{ $t(`status.${message.status}`) }}
      </fw-tag>
    </div>

    <div v-if="user && user.key" class="flex w-1/6 gap-2 items-center" title="Gestor/a">
      <fw-avatar size="xs" :user="user" class="flex-shrink-0" />
      <v-clamp class="font-semibold text-sm" autoresize :max-lines="1">
        {{ user.display_name || user.full_name }}
      </v-clamp>
    </div>
  </button>
</template>

<script>
export default {
  props: {
    message: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      default: () => {},
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "status": {
      "draft": "Rascunho",
      "sent": "Enviada"
    }
  },
  "en": {
    "status": {
      "draft": "Draft",
      "sent": "Sent"
    }
  }
}
</i18n>
