var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.league)?_c('div',[_c('fw-panel',{attrs:{"title":_vm.$t('leagueSettings'),"featured":"","sticky":"","loading":_vm.savingData,"after-loading-checked":""},scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_c('div',{staticClass:"flex gap-1 items-center"},[(_vm.canEdit && _vm.$v.$error)?_c('div',{staticClass:"p-1 mx-5 text-red-500 flex items-center gap-1 text-sm font-medium"},[_c('fw-icon-error-warning',{staticClass:"w-6 h-6"}),_c('span',{staticClass:"hidden lg:block"},[_vm._v(_vm._s(_vm.$t('thereAreErrors')))])],1):_vm._e(),(_vm.canEdit)?_c('div',{staticClass:"flex gap-5"},[(_vm.canEdit)?_c('fw-button',{attrs:{"type":"primary"},nativeOn:{"click":function($event){return _vm.updateLeague()}}},[_vm._v(_vm._s(_vm.$t('save')))]):_vm._e()],1):_vm._e()])]},proxy:true}],null,false,3618772866)}),(_vm.loading)?_c('LoadingPlaceholder'):_c('div',[_c('fw-panel',{staticClass:"my-5",attrs:{"title":_vm.$t('metadata'),"boxed":"lg","custom-class":"bg-white flex flex-col gap-3"}},[_c('div',[_c('fw-label',[_vm._v(_vm._s(_vm.$t('title.label')))]),(!_vm.canEdit)?_c('div',{staticClass:"flex flex-col gap-3"},_vm._l((_vm.langs),function(lang){return _c('div',{key:lang,staticClass:"flex gap-3"},[_c('div',{staticClass:"flex-shrink-0"},[_c('fw-tag',{staticClass:"uppercase"},[_vm._v(_vm._s(lang))])],1),(_vm.league.title && _vm.league.title[lang])?_c('span',[_vm._v(_vm._s(_vm.league.title[lang]))]):_c('fw-panel-info',{attrs:{"clean":""}},[_vm._v(_vm._s(_vm.$t('notDefined')))])],1)}),0):_c('TranslatedInput',{class:{
            error: _vm.$v.league.title.$error,
          },attrs:{"disabled":!_vm.canEdit,"multiline":false,"maxlength":250,"disable-autowrite":true,"rows":3,"placeholder":{
            pt: _vm.$t('title.placeholder.pt'),
            en: _vm.$t('title.placeholder.en'),
          }},model:{value:(_vm.league.title),callback:function ($$v) {_vm.$set(_vm.league, "title", $$v)},expression:"league.title"}}),(_vm.$v.league.title.$error)?_c('fw-tip',{attrs:{"error":""}},[_vm._v(" "+_vm._s(_vm.$t('title.required'))+" ")]):_vm._e()],1),_c('div',[(!_vm.canEdit)?_c('div',{staticClass:"flex flex-col gap-3"},[_c('fw-label',[_vm._v(_vm._s(_vm.$t('description.label')))]),_vm._l((_vm.langs),function(lang){return _c('div',{key:lang,staticClass:"flex gap-3"},[_c('div',{staticClass:"flex-shrink-0"},[_c('fw-tag',{staticClass:"uppercase"},[_vm._v(_vm._s(lang))])],1),(_vm.league.description && _vm.league.description[lang])?_c('span',{domProps:{"innerHTML":_vm._s(_vm.league.description[lang])}}):_c('fw-panel-info',{attrs:{"clean":""}},[_vm._v(_vm._s(_vm.$t('notDefined')))])],1)})],2):_c('div',[_c('fw-label',[_vm._v("Descrição em Português")]),_c('HtmlEditor',{staticClass:"mb-1.5 w-full content",attrs:{"editor-class":"min-h-40"},model:{value:(_vm.league.description['pt']),callback:function ($$v) {_vm.$set(_vm.league.description, 'pt', $$v)},expression:"league.description['pt']"}}),_c('fw-label',[_vm._v("Descrição em Inglês")]),_c('HtmlEditor',{staticClass:"mb-1.5 w-full content",attrs:{"editor-class":"min-h-40"},model:{value:(_vm.league.description['en']),callback:function ($$v) {_vm.$set(_vm.league.description, 'en', $$v)},expression:"league.description['en']"}})],1),(false)?_c('TranslatedInput',{class:{
            error: _vm.$v.league.description.$error,
          },attrs:{"disabled":!_vm.canEdit,"multiline":true,"disable-autowrite":true,"rows":3,"placeholder":{
            pt: _vm.$t('description.placeholder.pt'),
            en: _vm.$t('description.placeholder.en'),
          }},model:{value:(_vm.league.description),callback:function ($$v) {_vm.$set(_vm.league, "description", $$v)},expression:"league.description"}}):_vm._e(),(_vm.$v.league.description.$error)?_c('fw-tip',{attrs:{"error":""}},[_vm._v(" "+_vm._s(_vm.$t('description.required'))+" ")]):_vm._e()],1),_c('div',[_c('fw-label',[_vm._v(_vm._s(_vm.$t('procType.label')))]),_c('div',{staticClass:"font-medium uppercase text-gray-500"},[_vm._v(" "+_vm._s(_vm.league.type)+" ")])],1)])],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }