<template>
  <div v-if="league">
    <fw-panel :title="$t('leagueSettings')" featured sticky :loading="savingData" after-loading-checked>
      <template #toolbar>
        <div class="flex gap-1 items-center">
          <div v-if="canEdit && $v.$error" class="p-1 mx-5 text-red-500 flex items-center gap-1 text-sm font-medium">
            <fw-icon-error-warning class="w-6 h-6"></fw-icon-error-warning>
            <span class="hidden lg:block">{{ $t('thereAreErrors') }}</span>
          </div>
          <div v-if="canEdit" class="flex gap-5">
            <fw-button v-if="canEdit" type="primary" @click.native="updateLeague()">{{ $t('save') }}</fw-button>
          </div>
        </div>
      </template>
    </fw-panel>

    <LoadingPlaceholder v-if="loading" />

    <div v-else>
      <fw-panel :title="$t('metadata')" boxed="lg" class="my-5" custom-class="bg-white flex flex-col gap-3">
        <div>
          <fw-label>{{ $t('title.label') }}</fw-label>
          <div v-if="!canEdit" class="flex flex-col gap-3">
            <div v-for="lang in langs" :key="lang" class="flex gap-3">
              <div class="flex-shrink-0">
                <fw-tag class="uppercase">{{ lang }}</fw-tag>
              </div>
              <span v-if="league.title && league.title[lang]">{{ league.title[lang] }}</span>
              <fw-panel-info v-else clean>{{ $t('notDefined') }}</fw-panel-info>
            </div>
          </div>

          <TranslatedInput
            v-else
            v-model="league.title"
            :disabled="!canEdit"
            :multiline="false"
            :maxlength="250"
            :disable-autowrite="true"
            :rows="3"
            :class="{
              error: $v.league.title.$error,
            }"
            :placeholder="{
              pt: $t('title.placeholder.pt'),
              en: $t('title.placeholder.en'),
            }"
          />

          <fw-tip v-if="$v.league.title.$error" error>
            {{ $t('title.required') }}
          </fw-tip>
        </div>

        <div>
          <div v-if="!canEdit" class="flex flex-col gap-3">
            <fw-label>{{ $t('description.label') }}</fw-label>
            <div v-for="lang in langs" :key="lang" class="flex gap-3">
              <div class="flex-shrink-0">
                <fw-tag class="uppercase">{{ lang }}</fw-tag>
              </div>
              <span v-if="league.description && league.description[lang]" v-html="league.description[lang]"></span>
              <fw-panel-info v-else clean>{{ $t('notDefined') }}</fw-panel-info>
            </div>
          </div>
          <div v-else>
            <fw-label>Descrição em Português</fw-label>
            <HtmlEditor v-model="league.description['pt']" class="mb-1.5 w-full content" editor-class="min-h-40" />

            <fw-label>Descrição em Inglês</fw-label>
            <HtmlEditor v-model="league.description['en']" class="mb-1.5 w-full content" editor-class="min-h-40" />
          </div>

          <TranslatedInput
            v-if="false"
            v-model="league.description"
            :disabled="!canEdit"
            :multiline="true"
            :disable-autowrite="true"
            :rows="3"
            :class="{
              error: $v.league.description.$error,
            }"
            :placeholder="{
              pt: $t('description.placeholder.pt'),
              en: $t('description.placeholder.en'),
            }"
          />

          <fw-tip v-if="$v.league.description.$error" error>
            {{ $t('description.required') }}
          </fw-tip>
        </div>

        <div>
          <fw-label>{{ $t('procType.label') }}</fw-label>
          <div class="font-medium uppercase text-gray-500">
            {{ league.type }}
          </div>
        </div>
      </fw-panel>
    </div>
  </div>
</template>

<script>
import TranslatedInput from '@/fw-modules/fw-core-vue/ui/components/form/TranslatedInput'
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import HtmlEditor from '@/fw-modules/fw-core-vue/ui/components/form/HtmlEditor'

import { required, maxLength, minLength } from 'vuelidate/lib/validators'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'

export default {
  components: {
    TranslatedInput,
    LoadingPlaceholder,
    HtmlEditor,
  },

  props: {
    league: {
      type: Object,
      default: () => {
        return {}
      },
    },

    canEdit: {
      type: Boolean,
      default: true,
    },

    savingData: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      minEndDate: Dates.now()
        .subtract(1, 'day')
        .toDate(),
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },
    language() {
      return this.$store.state.language
    },
    secondaryLanguage() {
      return this.language === 'pt' ? 'en' : 'pt'
    },
    langs() {
      return [this.language, this.secondaryLanguage]
    },
  },

  validations() {
    return {
      league: {
        type: { required },
        title: { required, min: minLength(2), max: maxLength(250) },
        description: {
          pt: { required, min: minLength(2) },
          en: { required, min: minLength(2) },
        },
      },
    }
  },

  methods: {
    updateLeague() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        const payload = { ...this.league }
        this.$emit('update-league', payload)
      }
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "leagueSettings": "Definições da liga",
    "thereAreErrors": "Existem erros no formulário",
    "close": "Fechar",
    "edit": "Editar",
    "save": "Guardar",
    "delete": "Remover",
    "metadata": "Metadados",
    "leagueCode": "Código do procedimento",
    "date": {
      "label": "Data",
      "placeholder": "Escolha uma data"
    },
    "startDate": {
      "label": "Data de início",
      "min": "Escolha uma data mais recente",
      "required": "Insira uma data de início"
    },
    "endDate": {
      "label": "Data de fim",
      "min": "Escolha uma data a seguir a data de início",
      "required": "Insira uma data de fim"
    },
    "title": {
      "label": "Título",
      "placeholder": {
        "pt": "Título em Português",
        "en": "Título em Inglês"
      },
      "required": "Insira um título em Portugês e Inglês."
    },
    "description": {
      "label": "Descrição",
      "placeholder": {
        "pt": "Descrição em Português",
        "en": "Descrição em Inglês"
      },
      "required": "Insira uma descrição em Portugês e Inglês."
    },
    "charsLimit": "Limite de {limit} caracteres.",
    "procType": {
      "label": "Tipo",
      "placeholder": "Escolha um tipo",
      "scholarship": "Scholarship",
      "required": "Escolha o tipo do procedimento."
    },
    "notDefined": "Não definido"
  },
  "en": {
    "leagueSettings": "League metadata and settings",
    "thereAreErrors": "There are errors in the form",
    "close": "Close",
    "edit": "Edit",
    "save": "Save",
    "delete": "Remove",
    "date": {
      "label": "Date",
      "placeholder": "Choose a date"
    },
    "startDate": {
      "label": "Start date",
      "min": "Choose a more recent date",
      "required": "Enter a start date"
    },
    "endDate": {
      "label": "End Date",
      "min": "Choose a date after the start date",
      "required": "Enter an end date"
    },
    "title": {
      "label": "Title",
      "placeholder": {
        "pt": "Title in Portuguese",
        "en": "English title"
      },
      "required": "Enter a title in English and Portuguese."
    },
    "description": {
      "label": "Description",
      "placeholder": {
        "pt": "Description in Portuguese",
        "en": "Description in English"
      },
      "required": "Enter a description in English and Portuguese."
    },
    "charsLimit": "Limit of {limit} characters.",
    "procType": {
      "label": "Tipo",
      "placeholder": "Escolha um tipo",
      "scholarship": "Scholarship",
      "required": "Enter the league type."
    },
    "notDefined": "Not defined"
  }
}
</i18n>
