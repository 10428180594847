<template>
  <SidebarBase sticky>
    <template #main>
      <div class="flex-1">
        <ButtonSidebar
          :active="!view || view == 'dashboard'"
          :label="$t('dashboard')"
          icon="dashboard"
          @click.native="$emit('go-to-view', 'dashboard')"
        />
        <ButtonSidebar
          v-if="false"
          :active="!view || view == 'inbox'"
          :label="$t('inbox')"
          icon="inbox"
          counter="99"
          @click.native="$emit('go-to-view', 'inbox')"
        />

        <div v-for="league in leagues" :key="league.key">
          <ButtonSidebar
            v-if="(isOnlyTeamGroupManager && league.type == 'academic') || !isOnlyTeamGroupManager"
            :active="leagueKey && leagueKey == league.key"
            :label="league.title[language]"
            @click.native="$emit('go-to-view', 'dashboard', { leagueKey: league.key })"
          >
            <template #svg>
              <fw-icon-cube class="w-6 h-6" />
            </template>
            <template #suffix>
              <fw-icon-chevron-up v-if="leagueKey && leagueKey == league.key" class="w-4 h-4" />
              <fw-icon-chevron-down v-else class="w-4 h-4" />
            </template>
          </ButtonSidebar>

          <VerticalSteps
            v-if="leagueKey && leagueKey == league.key"
            :steps="isOnlyTeamGroupManager && league.type == 'academic' ? teamGroupManagerSteps : verticalSteps"
            :current-step="view ?? 'dashboard'"
            class="mb-2"
            :truncate-text="true"
            @clicked="$emit('go-to-view', $event, { leagueKey: league.key })"
          />
        </div>

        <ButtonSidebar
          v-if="checks.user.isGlobalManager"
          :active="view == 'people'"
          :label="$t('people')"
          icon="people"
          @click.native="$emit('go-to-view', 'people')"
        />
        <ButtonSidebar
          v-if="checks.user.isGlobalManager"
          :label="$t('activity')"
          :active="view == 'activity'"
          icon="activity"
          @click.native="$emit('go-to-view', 'activity')"
        />
        <ButtonSidebar
          v-if="checks.user.isGlobalManager"
          :label="$t('notifications')"
          :active="view == 'notifications'"
          icon="message-sent"
          @click.native="$emit('go-to-view', 'notifications')"
        />
        <ButtonSidebar
          v-if="checks.user.isGlobalManager"
          :active="view == 'settings' && !leagueKey"
          :label="$t('settings')"
          icon="settings"
          @click.native="$emit('go-to-view', 'settings')"
        />
      </div>
    </template>
  </SidebarBase>
</template>

<script>
import SidebarBase from '@/fw-modules/fw-core-vue/ui/components/sidebars/SidebarBase'
import ButtonSidebar from '@/fw-modules/fw-core-vue/ui/components/buttons/ButtonSidebar'
import VerticalSteps from '@/fw-modules/fw-core-vue/ui/components/buttons/VerticalSteps'

export default {
  components: {
    ButtonSidebar,
    SidebarBase,
    VerticalSteps,
  },

  props: {
    leagues: {
      type: Array,
      default: () => {},
    },
    checks: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      verticalSteps: [
        { value: 'tournaments', text: 'Torneios' },
        { value: 'teams', text: 'Equipas' },
        { value: 'messages', text: this.$t('bulkMessages') },
        { value: 'settings', text: 'Configurações' },
      ],
      teamGroupManagerSteps: [
        { value: 'tournaments', text: 'Torneios' },
        { value: 'teams', text: 'Equipas' },
      ],
    }
  },

  computed: {
    language() {
      return this.$store.state.language || 'pt'
    },

    view() {
      return this.$route.params.view ?? 'dashboard'
    },

    leagueKey() {
      return this.$route.params.leagueKey
    },

    isOnlyTeamGroupManager() {
      return (
        this.checks.user.isTeamGroupManager && !this.checks.user.isGlobalManager && !this.checks.user.isLeagueManager
      )
    },
  },

  methods: {},
}
</script>

<style lang="postcss">
a span:last-child {
  @apply text-black;
}
</style>

<i18n>
{
  "pt": {
    "dashboard": "Dashboard",
    "activity": "Atividade",
    "people": "Pessoas",
    "notifications": "Notificações",
    "settings": "Configurações",
    "metadata": "Metadados",
    "reports": "Mapas",
    "league": "Liga",
    "inbox": "Inbox",
    "bulkMessages": "Comunicações"
  },
  "en": {
    "dashboard": "Dashboard",
    "activity": "Activity",
    "people": "People",
    "notifications": "Notifications",
    "settings": "Settings",
    "metadata": "Metadata",
    "reports": "Reports",
    "league": "League",
    "inbox": "Inbox",
    "bulkMessages": "Bulk Messages"
  }
}
</i18n>
