<template>
  <fw-panel :title="$t('teams')" featured>
    <PanelManageTeams :edition-key="edition.key" :league-key="league.key" :is-academic="league?.type == 'academic'" />
  </fw-panel>
</template>

<script>
import PanelManageTeams from '@/components/panels/manage/PanelManageTeams'

export default {
  components: {
    PanelManageTeams,
  },

  props: {
    edition: {
      type: Object,
      required: true,
    },
    league: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      hideStatsOnSearching: false,
      loadingStats: false,
      loading: false,
      stats: [
        {
          label: '',
          value: 10,
          valueSuffix: null,
          description: 'Total',
        },
        {
          label: '',
          value: 3,
          valueSuffix: null,
          description: 'Coisas',
        },
        {
          label: '',
          value: 7,
          valueSuffix: null,
          description: 'Coisitas',
        },
      ],
      isModalActive: false,
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },
    user() {
      return this.$store.getters.getUser
    },
  },

  methods: {},
}
</script>

<i18n>
{
  "pt": {
    "teams": "Equipas"
  },
  "en": {
    "teams": "Teams"
  }
}
</i18n>
