var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.edition)?_c('div',[_c('fw-panel',{attrs:{"title":_vm.$t('editionSettings'),"featured":"","sticky":"","loading":_vm.savingData,"after-loading-checked":""},scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_c('div',{staticClass:"flex gap-1 items-center"},[(_vm.canEdit && _vm.$v.$error)?_c('div',{staticClass:"p-1 mx-5 text-red-500 flex items-center gap-1 text-sm font-medium"},[_c('fw-icon-error-warning',{staticClass:"w-6 h-6"}),_c('span',{staticClass:"hidden lg:block"},[_vm._v(_vm._s(_vm.$t('thereAreErrors')))])],1):_vm._e(),(_vm.canEdit)?_c('div',{staticClass:"flex gap-5"},[(_vm.canEdit)?_c('fw-button',{attrs:{"type":"primary"},nativeOn:{"click":function($event){return _vm.updateEdition()}}},[_vm._v(_vm._s(_vm.$t('save')))]):_vm._e()],1):_vm._e()])]},proxy:true}],null,false,488484649)}),(_vm.loading)?_c('LoadingPlaceholder'):_c('div',[_c('fw-panel',{staticClass:"my-5",attrs:{"title":_vm.$t('metadata'),"boxed":"lg","custom-class":"bg-white flex flex-col gap-3"}},[_c('div',[_c('fw-label',[_vm._v("Nome da edição")]),(_vm.canEdit)?_c('TextInput',{class:{
            error: _vm.$v.edition.name.$error,
          },attrs:{"disabled":!_vm.canEdit,"minlength":2,"maxlength":250,"help":_vm.$t('charsLimit', { limit: '250' })},model:{value:(_vm.$v.edition.name.$model),callback:function ($$v) {_vm.$set(_vm.$v.edition.name, "$model", $$v)},expression:"$v.edition.name.$model"}}):_c('div',[(_vm.edition.name)?_c('div',[_vm._v(" "+_vm._s(_vm.edition.name)+" ")]):_c('fw-panel-info',{attrs:{"clean":""}},[_vm._v(_vm._s(_vm.edition.name || _vm.$t('notDefined')))])],1),(_vm.$v.edition.name.$error)?_c('fw-tip',{attrs:{"error":""}},[(!_vm.$v.edition.name.required)?_c('span',[_vm._v(_vm._s(_vm.$tc('errors.required', 1, { field: 'LUGUS' })))]):_c('span',[_vm._v(_vm._s(_vm.$t('errors.invalid', { field: 'LUGUS' })))])]):_vm._e()],1)]),_c('fw-panel',{staticClass:"my-5",attrs:{"title":'Calendarização',"boxed":"lg","custom-class":"bg-white flex flex-col gap-3"}},[_c('div',{staticClass:"flex flex-row gap-5"},[_c('div',{staticClass:"sm:w-1/2"},[_c('span',{staticClass:"font-semibold text-gray-500 mb-1 text-xs"},[_vm._v(_vm._s(_vm.$t('startDate.label')))]),(!_vm.canEdit)?_c('div',{staticClass:"font-medium"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm.calendar.start_at))+" ")]):_c('b-datepicker',{class:{
              error: _vm.$v.calendar.start_at.$error,
            },attrs:{"placeholder":_vm.$t('date.placeholder'),"icon":"calendar-alt","locale":"pt-PT","min-date":_vm.minDate,"trap-focus":"","disabled":!_vm.canEdit && _vm.canEdit},on:{"icon-right-click":function($event){_vm.calendar.start_at = null}},model:{value:(_vm.calendar.start_at),callback:function ($$v) {_vm.$set(_vm.calendar, "start_at", $$v)},expression:"calendar.start_at"}}),(_vm.$v.calendar.start_at.$error)?_c('fw-tip',{attrs:{"error":""}},[(!_vm.$v.calendar.start_at.min)?_c('span',[_vm._v(_vm._s(_vm.$t('startDate.min')))]):_c('span',[_vm._v(_vm._s(_vm.$t('startDate.required')))])]):_vm._e()],1),_c('div',{staticClass:"sm:w-1/2"},[_c('span',{staticClass:"font-semibold text-gray-500 mb-1 text-xs"},[_vm._v(_vm._s(_vm.$t('endDate.label')))]),(!_vm.canEdit)?_c('div',{staticClass:"font-medium"},[_vm._v(_vm._s(_vm._f("formatDate")(_vm.calendar.end_at)))]):_c('b-datepicker',{class:{
              error: _vm.$v.calendar.end_at.$error,
            },attrs:{"placeholder":_vm.$t('date.placeholder'),"icon":"calendar-alt","trap-focus":"","min-date":_vm.calendar.start_at ?? _vm.minDate,"locale":"pt-PT","position":"is-bottom-left","disabled":!_vm.canEdit && _vm.canEdit},on:{"icon-right-click":function($event){_vm.calendar.end_at = null}},model:{value:(_vm.calendar.end_at),callback:function ($$v) {_vm.$set(_vm.calendar, "end_at", $$v)},expression:"calendar.end_at"}}),(_vm.$v.calendar.end_at.$error)?_c('fw-tip',{attrs:{"error":""}},[(!_vm.$v.calendar.end_at.min)?_c('span',[_vm._v(_vm._s(_vm.$t('endDate.min')))]):_c('span',[_vm._v(_vm._s(_vm.$t('endDate.required')))])]):_vm._e()],1)])])],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }