<template>
  <PanelNotifications
    :active-filters="activeFilters"
    :inject-payload="{ editionKey: editionKey }"
    :show-export-toolbar="false"
  />
</template>

<script>
import PanelNotifications from '@/fw-modules/fw-core-vue/ui/components/panels/PanelNotifications'

export default {
  components: {
    PanelNotifications,
  },

  props: {
    editionKey: {
      type: String,
    },
  },

  data() {
    return {
      activeFilters: {
        edition: this.editionKey,
      },
    }
  },
}
</script>
