<template>
  <fw-panel :title="'Criar torneios'" :loading="loading">
    <div>
      <fw-label>{{ $t('title.label') }}</fw-label>
      <TranslatedInput
        v-model="tournament.title"
        :placeholder="{ pt: $t('title.pt'), en: $t('title.en') }"
        :disable-autowrite="true"
        :multiline="false"
        :class="{
          error: $v.tournament.title.$error,
        }"
      />
      <fw-tip v-if="$v.tournament.title.$error" error>
        {{ $t('title.required') }}
      </fw-tip>
    </div>

    <div class="mt-4">
      <fw-label>{{ $t('description.label') }}</fw-label>
      <TranslatedInput
        v-model="tournament.description"
        :placeholder="{ pt: $t('description.pt'), en: $t('description.en') }"
        :disable-autowrite="true"
        :multiline="true"
      />
      <fw-tip v-if="$v.tournament.description.$error" error>
        {{ $t('description.required') }}
      </fw-tip>
    </div>

    <div class="mt-4">
      <fw-label>Adicionar modalidade</fw-label>
      <div class="flex flex-col gap-3">
        <b-select v-model="tournament.sport_key" placeholder="Seleccione uma modalidade" expanded>
          <option v-for="sport in sports" :key="sport.key" :value="sport.key">
            {{ `${sport.modality.title[language]} ${sport.category[language] ?? ''}` }}
          </option>
        </b-select>
      </div>
      <fw-tip v-if="$v.tournament.sport_key.$error" error> Insira a modalidades dos novos torneios</fw-tip>
    </div>

    <div class="mt-4">
      <fw-label>Género</fw-label>
      <b-field>
        <b-checkbox-button
          v-model="tournament.genders"
          native-value="M"
          class="bg-gray-100 !rounded-r-none rounded-l-xl text-xs"
          size="is-small"
        >
          <span>{{ gendersList['M'][language] }}</span>
        </b-checkbox-button>

        <b-checkbox-button
          v-model="tournament.genders"
          native-value="F"
          class="bg-gray-100 !rounded-none text-xs"
          size="is-small"
        >
          <span>{{ gendersList['F'][language] }}</span>
        </b-checkbox-button>

        <b-checkbox-button
          v-model="tournament.genders"
          native-value="U"
          class="bg-gray-100 !rounded-l-none rounded-r-xl text-xs"
          size="is-small"
        >
          {{ gendersList['U'][language] }}
        </b-checkbox-button>
      </b-field>
    </div>

    <div v-for="(stage, index) in tournament.stages" :key="index" class="mt-5">
      <fw-heading
        size="md"
        :class="{
          'mb-2': index == 0,
          'mb-2 mt-5': index != 0,
        }"
        >{{ stages[stage.stage]?.[language] }}</fw-heading
      >

      <div>
        <fw-label>Tipo</fw-label>
        <b-select v-model="stage.type" placeholder="Tipo" expanded>
          <option v-for="(option, key) in typesList" :key="key" :value="key">
            {{ option[language] }}
          </option>
        </b-select>
        <fw-tip v-if="$v.tournament.stages.$each[index].type.$error" error> Insira a edição dos novos torneios</fw-tip>
      </div>

      <div class="mt-4">
        <fw-label>Número máximo de equipas por torneio</fw-label>
        <div class="flex flex-col gap-3">
          <NumberInput v-model="stage.max_teams" :placeholder="'Número de equipas'" :maxlength="20"></NumberInput>
        </div>
        <fw-tip v-if="$v.tournament.stages.$each[index].max_teams.$error" error>
          Insira o número máximo de equipas</fw-tip
        >
      </div>

      <div v-if="stage.stage == 'intragroups'" class="mt-4">
        <fw-label>Núcleo de estudantes</fw-label>
        <b-select v-model="stage.team_group" placeholder="Selecione um grupo" expanded @blur="$emit('save-team')">
          <option v-for="teamGroup in teamGroups" :key="teamGroup.key" :value="teamGroup">{{
            `${teamGroup.organic_unit.name} - ${teamGroup.name}`
          }}</option>
        </b-select>

        <fw-tip v-if="$v.tournament.stages.$each[index].team_group.$error" error>
          Indique o núcleo de estudantes
        </fw-tip>
      </div>
    </div>

    <div class="flex flex-shrink-0 pt-5 gap-3">
      <div class="flex-1"></div>
      <fw-button type="link-muted" @click.native="closeModal()">
        {{ 'Cancelar' }}
      </fw-button>
      <fw-button :type="'primary'" :disable="loading" :saving="loading" class="w-28" @click.native="createTournament()">
        {{ 'Criar' }}
      </fw-button>
    </div>
  </fw-panel>
</template>

<script>
import NumberInput from '@/fw-modules/fw-core-vue/ui/components/form/NumberInput'
import TranslatedInput from '@/fw-modules/fw-core-vue/ui/components/form/TranslatedInput'

import { required, minLength, numeric, minValue, requiredIf } from 'vuelidate/lib/validators'
import { GENDERS, TOURNAMENT_TYPES, TOURNAMENT_STAGES, hasOnlyFinalStage } from '@/utils/index.js'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  name: 'ModalCreateTournaments',
  components: {
    NumberInput,
    TranslatedInput,
  },
  props: {
    league: {
      type: Object,
      required: true,
    },
    editionKey: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      minSignUpDate: Dates.now()
        .subtract(1, 'day')
        .toDate(),
      minDate: Dates.now()
        .add(1, 'day')
        .toDate(),
      sports: [],
      stages: TOURNAMENT_STAGES,
      organicUnits: [],
      teamGroups: [],
      tournament: {
        genders: [],
        title: {
          pt: '',
          en: '',
        },
        description: {
          pt: '',
          en: '',
        },
        sport_key: null,
        stages: [],
        // stages: [{
        //   stage: null,
        //   type: null,
        //   max_teams: 2,
        //   team_group: null,
        // }],
      },
      loading: true,
      gendersList: GENDERS,
      typesList: TOURNAMENT_TYPES,
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    language() {
      return this.$store.state.language || 'pt'
    },

    hasOnlyFinalStage() {
      const tournamentSport = this.sports.find(sport => sport.key === this.tournament.sport_key)

      return hasOnlyFinalStage(this.league, tournamentSport)
    },
  },

  validations() {
    return {
      tournament: {
        genders: { required, min: minLength(1) },
        title: {
          pt: { required, min: minLength(1) },
          en: { required, min: minLength(1) },
        },
        description: {
          pt: { required, min: minLength(1) },
          en: { required, min: minLength(1) },
        },
        sport_key: { required, min: minLength(1) },
        stages: {
          $each: {
            stage: { required, min: minLength(1) },
            type: { required, min: minLength(1) },
            max_teams: { required, min: minValue(1), numeric },

            team_group: {
              required: requiredIf(nestedModal => {
                return nestedModal.stage == 'intragroups'
              }),
            },
          },
        },
      },
    }
  },

  mounted() {
    this.getData()
    if (this.hasOnlyFinalStage) {
      this.tournament.stages = [
        {
          stage: 'final',
          type: 'single_elimination',
          max_teams: 2,
        },
      ]
    } else {
      for (const key in TOURNAMENT_STAGES) {
        this.tournament.stages.push({
          stage: key,
          type: 'single_elimination',
          max_teams: 2,
        })
      }
    }
  },

  methods: {
    async getData() {
      this.loading = true
      if (this.league?.type == 'academic') {
        Promise.all([this.getSports(), this.getTeamGroups()])
      } else {
        this.getSports()
      }
      this.loading = false
    },

    async getSports() {
      try {
        const response = await this.api.getSports()
        console.log('getData :>> ', response)
        this.sports = response.sports
      } catch (error) {
        console.error('getData Error: ', error)
      }
    },

    async getOrganicUnits() {
      utils.tryAndCatch(this, async () => {
        const response = await this.api.getOrganicUnits()
        console.log('getOrganicUnits :>> ', response)
        this.organicUnits = response.data
      })
    },

    async getTeamGroups() {
      utils.tryAndCatch(this, async () => {
        const response = await this.api.getTeamGroups({
          sort: 'name',
          direction: 'asc',
          with_org_units: true,
        })
        console.log('getTeamGroups :>> ', response)
        this.teamGroups = response.groups.sort((a, b) =>
          `${a.organic_unit.name} - ${a.name}`.localeCompare(`${b.organic_unit.name} - ${b.name}`)
        )
      })
    },

    createTournament() {
      this.$v.tournament.$touch()
      if (this.$v.tournament.$invalid) return

      let tournamentStages = JSON.parse(JSON.stringify(this.tournament.stages))

      for (const stage of tournamentStages) {
        stage.organic_unit = stage.team_group?.organic_unit?.name?.toLowerCase()
        stage.team_group = stage.team_group?.name
      }

      this.$emit('create-tournament', { ...this.tournament, stages: tournamentStages })
    },

    closeModal() {
      this.$emit('close')
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "title": {
      "label": "Título",
      "pt": "Título em português",
      "en": "Título em inglês",
      "required": "Insira um título em português e inglês."
    },
    "description": {
      "label": "Descrição",
      "pt": "Descrição em português",
      "en": "Descrição em inglês",
      "required": "Insira uma descriçao em português e inglês."
    }
  },
  "en": {
    "title": {
      "label": "Title",
      "pt": "Title in Portuguese",
      "en": "Title in English",
      "required": "Enter a title in Portuguese and English."
    },
    "description": {
      "label": "Description",
      "pt": "Description in Portuguese",
      "en": "Description in English",
      "required": "Insira uma descriçao in Portuguese and English."
    }
  }
}
</i18n>

<style>
.b-checkbox.button {
  border-radius: inherit !important;
}
</style>
